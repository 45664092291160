// 引入qiankun
import { registerMicroApps, start } from 'qiankun';
//定义应用
const apps = [
    {
        name: "productGeneral",                 //子应用名称
        //entry: "https://abb.modon.cc/generalapplication",    //子应用地址（子应用必须支持跨域）  http://39.153.183.101:30395     http://localhost:8888/
        // entry:window.xconfig.dev_url+"/generalapplication",
        entry: "https://electrification.iot.ability.abb.com.cn/elproduct",
        container: "#productGeneral",           //将子应用挂在到id为productGeneral的元素上
        activeRule: "/productGeneral/:module",          //激活规则为以/productGeneral开头的路由
    },
    // {
    //     name: "productGeneral",                 //子应用名称
    //     entry: "http://39.153.183.101:30750/generalapplication",    //子应用地址（子应用必须支持跨域）  http://39.153.183.101:30395     http://localhost:8888/
    //     container: "#productGeneral",           //将子应用挂在到id为productGeneral的元素上
    //     activeRule: "/productGeneral/:module",          //激活规则为以/productGeneral开头的路由
    // }
    // {
    //     name: "productGeneral",                 //子应用名称
    //     entry: "http://39.153.183.101:2222",    //子应用地址（子应用必须支持跨域）  http://39.153.183.101:30395     http://localhost:8888/
    //     container: "#productGeneral",           //将子应用挂在到id为productGeneral的元素上
    //     activeRule: "/productGeneral/:module",          //激活规则为以/productGeneral开头的路由
    // },
    
    // {
    //     name: "productGeneral",                 //子应用名称
    //     entry: "https://electrification.iot.ability.abb.com.cn/elproduct",    //子应用地址（子应用必须支持跨域）  http://39.153.183.101:30395     http://localhost:8888/
    //     container: "#productGeneral",           //将子应用挂在到id为productGeneral的元素上
    //     activeRule: "/productGeneral/:module",          //激活规则为以/productGeneral开头的路由
    // }
    // {
    //     name: "productGeneral",                 //子应用名称
    //     entry: "https://env01.abilitylite01.abb.com.cn/elproduct",    //子应用地址（子应用必须支持跨域）  http://39.153.183.101:30395     http://localhost:8888/
    //     container: "#productGeneral",           //将子应用挂在到id为productGeneral的元素上
    //     activeRule: "/productGeneral/:module",          //激活规则为以/productGeneral开头的路由
    // }
    // {
    //     name: "productGeneral",                 //子应用名称
    //     entry: "https://iot-maks.lshenergy.com/elproduct",    //子应用地址（子应用必须支持跨域）  http://39.153.183.101:30395     http://localhost:8888/
    //     container: "#productGeneral",           //将子应用挂在到id为productGeneral的元素上
    //     activeRule: "/productGeneral/:module",          //激活规则为以/productGeneral开头的路由
    // }
]
//注册子应用
registerMicroApps(apps);
//开始调用子应用
start({
    excludeAssetFilter: url => url.indexOf('api.map.baidu.com') !== -1
  })

